.App {
  display: flex;
  background-color: transparent;
  height: 100vh;
  width: 100%;
  line-height: normal;
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

*,
::after,
::before {
  box-sizing: content-box;
}

.anticon {
  vertical-align: middle;
}


.sending-loader {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: rgba(33, 33, 33, 0.95);
  z-index: 11115;
}

.sending-loader > span:nth-child(2) {
  padding: 3rem 4rem;
  background-color: white;
  border: 8px solid green;
  color: green;
  font-size: 22px;
  font-weight: 700;
  display: none;
}

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  color: #fff;
}

.loader:before,
.loader:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}

.loader:after {
  color: #FF3D00;
  transform: rotateY(70deg);
  animation-delay: .4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {

  0%,
  100% {
    box-shadow: .2em 0px 0 0px currentcolor;
  }

  12% {
    box-shadow: .2em .2em 0 0 currentcolor;
  }

  25% {
    box-shadow: 0 .2em 0 0px currentcolor;
  }

  37% {
    box-shadow: -.2em .2em 0 0 currentcolor;
  }

  50% {
    box-shadow: -.2em 0 0 0 currentcolor;
  }

  62% {
    box-shadow: -.2em -.2em 0 0 currentcolor;
  }

  75% {
    box-shadow: 0px -.2em 0 0 currentcolor;
  }

  87% {
    box-shadow: .2em -.2em 0 0 currentcolor;
  }
}
.editing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 100vh;
    padding: 1rem 0;
    box-sizing: border-box;
    background-color: #121212;
    overflow-y: scroll;
}

.editing-container-top-container {
    display: flex;
    gap: 0.7rem;
    max-width: 95%;
    width: max-content;
    border-radius: 0.75rem;
    border: 1px solid rgba(204, 204, 204, 0.23);
    background: rgba(150, 150, 150, 0.37);
    backdrop-filter: blur(12.399999618530273px);
    padding: 0.8rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.editing-container-top-container>div {
    display: flex;
    flex-wrap: wrap;
    gap: 0.7rem;
    align-items: center;
}

.ReactCrop {
    width: 100%;
}

.ReactCrop__child-wrapper>img, .ReactCrop__child-wrapper>video {
    width: 100%;
    height: auto;
}

input {
    height: initial;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
}

input::placeholder {
    color: rgba(0, 0, 0, 0.28);
}

input:focus {
    border-color: #4096ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    border-inline-end-width: 1px;
    outline: 0;
}

input:hover {
    border-color: #4096ff;
    border-inline-end-width: 1px;
}

/* .editing-container-top-container > input {
    background-color: grey;
    color: white;
    padding: 0.5rem;
    width: 100px;
    height: 20px;
    font-size: 16px;
}

input {
    background-color: grey;
    color: white;
    padding: 0.5rem;
    width: 150px;
    height: 20px;
    font-size: 16px;
}

input::placeholder {
    color: white;
}

select {
    height: 20px;
    background-color: black;
    padding: 0.5rem;
    color: white;
    font-size: 16px;
} */

/* form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
} */

/* .editing-container-top-container > div:nth-child(11),
.editing-container-top-container > div:nth-child(12),
.edit_variant_image_button {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 2px solid white;
    padding: 0.5rem;
    background-color: rgb(67, 67, 67);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
} */

/* .edit_variant_image_button {
    overflow: hidden;
    height: 20px;
    align-items: flex-start;
    justify-content: flex-start;
} */

/* .editing-container-top-container > div:nth-child(13) {
    color: white;
    display: none;
    align-items: center;
    justify-content: center;
    width: 120px;
    border: 2px solid white;
    padding: 0.5rem;
    background-color: rgb(67, 67, 67);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

#edit_model_image,
#edit_upload_variant_image {
    display: none;
} */

/* .editing-container-top-container > div:nth-child(3) {
    color: white;
    display: flex;
    height: 20px;
    border: 2px solid white;
    padding: 0.5rem;
    background-color: rgb(67, 67, 67);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
}

.editing-container-top-container > span {
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.editing-container-top-container > div:nth-child(14) {
    color: white;
    display: none;
    align-items: center;
    justify-content: center;
    width: 120px;
    border: 2px solid white;
    padding: 0.5rem;
    background-color: rgb(67, 67, 67);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
} */

/* .editing-container-top-container > button {
    color: white;
    border: 10px;
    border: 2px solid white;
    padding: 0.5rem;
    width: 160px;
    font-size: 16px;
    background-color: rgb(67, 67, 67);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
} */

/* .editing-container-top-container > div:nth-child(4) {
    color: white;
    border: 10px;
    border: 2px solid white;
    padding: 0.5rem;
    width: 160px;
    font-size: 16px;
    background-color: rgb(67, 67, 67);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
} */

.model_search {
    background-color: grey;
    color: white;
    width: 170px;
    height: 100%;
    font-size: 16px;
}

.countryDiv {
    display: none;
    position: absolute;
    width: 170px;
    background: black;
    max-height: 240px;
    overflow-y: hidden;
}

.countryName {
    padding: 0.5rem;
}

.countryName:hover {
    background-color: grey;
    cursor: pointer;
}

.edit_variant_price {
    border-left: 3px solid white;
}

.edit_variant_color_name {
    border-right: 3px solid white;
}

.editing-container-city-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: black;
    padding: 1rem;
    align-items: center;
    justify-content: flex-start;
    color: white;
}

.city-container-header {
    border-bottom: 2px solid white;
    width: 100%;
    padding: 0rem 4rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.city-container-header>span {
    font-size: 22px;
}

.city-container-header>div {
    display: flex;
    gap: 1rem;
}

.city-container-header>div>div {
    color: white;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100px;
    border: 2px solid white;
    padding: 0.5rem;
    background-color: rgb(67, 67, 67);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-right: 3rem;
}

.city-container-header>div>div:nth-child(1) {
    display: flex;
}

.city-variant-container {
    display: flex;
    border: 2px solid white;
    width: 90%;
    padding: 1rem;
}

.city-variant-name {
    width: 25%;
    font-size: 18px;
    font-weight: 700;
}

.city-variant-colors-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.city-colors-container {
    display: flex;
    gap: 0.5rem;
}

.city-colors-container>div>input {
    background-color: grey;
    color: white;
    padding: 0.5rem;
    width: 120px;
    height: 20px;
    font-size: 16px;
}

.city-colors-container>div>input::placeholder {
    color: white;
}

.city-variant-container>button {
    margin-left: 4rem;
    height: 100%;
}
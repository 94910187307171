.making-container > :where(.css-dev-only-do-not-override-1m62vyb).ant-btn,
.making-container > :where(.css-1m62vyb).ant-btn {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    padding: 0 1rem;
    height: max-content;
}
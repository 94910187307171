.making-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 100vh;
    padding: 1rem 0;
    box-sizing: border-box;
    background-color: #121212;
    overflow-y: scroll;
}

.making-container-top-container {
    display: flex;
    gap: 0.7rem;
    max-width: 95%;
    width: 95%;
    border-radius: 0.75rem;
    border: 1px solid rgba(204, 204, 204, 0.23);
    background: rgba(150, 150, 150, 0.37);
    backdrop-filter: blur(12.399999618530273px);
    padding: 0.8rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

.making-container-top-container > div,
.making-container-top-container.supplier > form {
    display: flex;
    flex-wrap: wrap;
    gap: 0.7rem;
}

.all-make-entries-container {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    max-width: 95%;
    width: 95%;
    height: 100%;
    overflow-x: scroll;
    padding: 1rem 0;
}

.all-make-entries-container::-webkit-scrollbar {
    display: none;
}

.all-make-city-container {
    display: flex;
    border-radius: 0.75rem;
    gap: 0.7rem;
    border: 1px solid rgba(255, 255, 255, 0.63);
    background: rgba(255, 255, 255, 0.65);
    backdrop-filter: blur(12.399999618530273px);
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
}

.all-make-entry-container {
    display: flex;
    border-radius: 0.75rem;
    gap: 0.7rem;
    border: 1px solid rgba(204, 204, 204, 0.23);
    background: rgba(150, 150, 150, 0.37);
    backdrop-filter: blur(12.399999618530273px);
    align-items: center;
    padding: 0.8rem;
    width: max-content;
}

.specifications-modal-container {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.ant-select-show-search:where(.css-dev-only-do-not-override-1m62vyb).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-show-search:where(.css-1m62vyb).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-collapse>.ant-collapse-item >.ant-collapse-header,
:where(.css-1m62vyb).ant-collapse>.ant-collapse-item >.ant-collapse-header {
    color: white;
    align-items: center;
    font-size: 14px;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-collapse .ant-collapse-content>.ant-collapse-content-box,
:where(.css-1m62vyb).ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 0;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-modal-root .ant-modal-wrap,
:where(.css-1m62vyb).ant-modal-root .ant-modal-wrap {
    height: 100%;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-modal-root .ant-modal-centered .ant-modal,
:where(.css-1m62vyb).ant-modal-root .ant-modal-centered .ant-modal {
    height: 85%;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-modal .ant-modal-content,
:where(.css-1m62vyb).ant-modal .ant-modal-content {
    height: 100%;
    overflow-y: scroll;
}
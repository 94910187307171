.banking-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: #121212;
}

.scheme-entry-container {
    display: flex;
    gap: 1rem;
    width: 98%;
    border-radius: 0.75rem;
    border: 1px solid rgba(204, 204, 204, 0.23);
    background: rgba(150, 150, 150, 0.37);
    backdrop-filter: blur(12.399999618530273px);
    padding: 0.8rem;
    flex-direction: row;
    align-items: center;
    min-height: 25%;
    flex-wrap: wrap;
}

.scheme-entry-inner-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-left: 1rem;
    width: calc(100% - 310px - 2rem);
}

.scheme-entry-inner-container input,
.scheme-entry-inner-container select,
.bank-scheme-container input,
.bank-container-header input {
    width: 180px;
    height: initial;
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
}

.scheme-entry-inner-container input::placeholder,
.bank-container-header input::placeholder,
.bank-scheme-container input::placeholder {
    color: lightgrey;
}

.banking-container-bank-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 98%;
    max-height: 75%;
}

.bank-container-header {
    display: flex;
    justify-content: space-between;
    border-radius: 0.75rem;
    padding: 0.8rem;
    align-items: center;
    flex-wrap: wrap;
}

.bank-container-header>span {
    display: flex;
    gap: 2rem;
    align-items: center;
    flex-wrap: wrap;
}

.bank-container-header>span>h4 {
    color: black;
}

.schemes-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
    overflow-y: scroll;
}

.schemes-container::-webkit-scrollbar {
    width: 5px;
}

.schemes-container::-webkit-scrollbar-track {
    background: transparent;
}

.schemes-container::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 10px;
}

.bank-scheme-container {
    display: flex;
    justify-content: space-between;
    border-radius: 0.75rem;
    border: 1px solid rgba(204, 204, 204, 0.23);
    background: rgba(150, 150, 150, 0.37);
    backdrop-filter: blur(12.399999618530273px);
    padding: 0.8rem;
    align-items: center;
}

.bank-scheme-container>div {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.coupons-list {
    display: flex;
    flex-direction: column;
    height: 50vh;
    overflow-y: scroll;
    width: 100%;
    gap: 0.7rem;
}

.coupons-list::-webkit-scrollbar {
    display: none;
}

.coupons-list-head {
    display: flex;
    width: 100%;
    gap: 1rem;
}

.coupons-list-head>span {
    width: calc(22% + 0.2rem);
    font-weight: 600;
    font-size: 15px;
}

.coupons-list-item {
    display: flex;
    width: 100%;
    gap: 1rem;
}

.coupon-form {
    display: flex;
    gap: 1rem;
}

.coupon-form>button {
    display: flex;
    justify-content: center;
    align-items: center;
}
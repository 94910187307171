.agencies-container {
    display: none;
}

.agencies-container.active {
    display: flex;
}

.locations-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.locations-header {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    padding: 0.5rem 1.8rem 0.5rem 1rem;
    gap: 1rem;
}

.locations-header>div {
    width: 20%;
}

.locations-header>div:nth-child(3),
.locations-header>div:nth-child(4) {
    width: 30%;
}

.locations-list {
    display: flex;
    flex-direction: column;
    height: 60vh;
    overflow-y: scroll;
}

.locations-item {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    padding: 0.8rem 1rem;
    gap: 1rem;
    border-bottom: 1px solid black;
}

.locations-item>div {
    width: 20%;
}

.locations-item>div:nth-child(3),
.locations-item>div:nth-child(4) {
    width: 30%;
}

.locations-item:last-child {
    border: none;
    display: flex;
    justify-content: center;
}